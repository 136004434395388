
var useUrl=true;
if (useUrl) {   
module.exports = {
    // USER_URL:"http://192.168.0.78:3001",
    // USER_URL:"http://localhost:3001",
    // USER_URL:"https://sandbox-payment.foloosi.com",
    USER_URL:"https://payments.foloosi.com",
    //USER_URL:"https://order.foloosi.com",

    BASE_URL : "",
    PAGE_NAME:{
    	Root : '/pay',
        Home : '/pay',
        Success : '/success',
        Cancel : '/cancel',
        Decline : '/decline',   
        Directsuccess:'/directsuccess',
        Directcancel:'/directcancel',
        CardCancel:'/card-cancel',
        SamsungCancel:'/samsungpay-cancel',
        SamsungPay:'/samsung-pay',
        LinkPaid:'/notfound'
    },
    //API_URL:'https://roamsoft.co:8443/v1/',
    API_URL:'https://api.foloosi.com/aggregatorproduct/',
    API_PRODUCT:"https://api.foloosi.com/v1/customer/product-preview/",
    API_ORDER:"https://api.foloosi.com/v1/customer/product/initiate-order/",
    //API_URL:'https://flstrans.foloosi.com/aggregatorproduct/',
    //API_URL:'http://15.185.129.4:8000/v1/',
    SAMSUNG_PAY: "https://api.foloosi.com/aggregator/web/spay/order/create",
    GET_SAMSUNG_TOKEN: "https://api.foloosi.com/aggregator/web/spay/payment/data",
    APPLE_PAY:"https://api.foloosi.com/aggregator/",
    /* SAMSUNG_PAY: "https://flstrans.foloosi.com/aggregator/web/spay/order/create",
    GET_SAMSUNG_TOKEN: "https://flstrans.foloosi.com/aggregator/web/spay/payment/data", */
    GET_COUNTRY :'https://pro.ip-api.com/json?key=RMV9nkZYs1rNPZQ',
    Neutrinoapi :'https://neutrinoapi.net/',
    NeutrinoKey: 'HBYAkwD7MvWPGvs9w4kle2BXrOnOwLBuhRGQ14NfJylEE5rF',
    API_LIST: {
        CHECK_TOKEN : 'check-product',
        CHARGE_REQUEST:'payment-request',
        QtyChange : 'https://api.foloosi.com/v1/customer/product/quantity-update'
    }
}
}else{
    module.exports = {
        // USER_URL:"http://192.168.0.78:3001",
        // USER_URL:"http://localhost:3003",
        USER_URL:"https://sandbox-payment.foloosi.com",
        // USER_URL:"https://payments.foloosi.com",
        //USER_URL:"https://order.foloosi.com",
    
        BASE_URL : "",
        PAGE_NAME:{
            Root : '/pay',
            Home : '/pay',
            Success : '/success',
            Cancel : '/cancel',
            Decline : '/decline',   
            Directsuccess:'/directsuccess',
            Directcancel:'/directcancel',
            CardCancel:'/card-cancel',
            SamsungCancel:'/samsungpay-cancel',
            SamsungPay:'/samsung-pay',
            LinkPaid:'/notfound'
        },
        //API_URL:'https://roamsoft.co:8443/v1/',
        API_URL:'https://api.foloosi.com/aggregatorproduct/',
        API_PRODUCT:"https://api.foloosi.com/v1/customer/product-preview/",
        API_ORDER:"https://api.foloosi.com/v1/customer/product/initiate-order/",
        //API_URL:'https://flstrans.foloosi.com/aggregatorproduct/',
        //API_URL:'http://15.185.129.4:8000/v1/',
        SAMSUNG_PAY: "https://api.foloosi.com/aggregator/web/spay/order/create",
        GET_SAMSUNG_TOKEN: "https://api.foloosi.com/aggregator/web/spay/payment/data",
        APPLE_PAY:"https://api.foloosi.com/aggregator/",
        /* SAMSUNG_PAY: "https://flstrans.foloosi.com/aggregator/web/spay/order/create",
        GET_SAMSUNG_TOKEN: "https://flstrans.foloosi.com/aggregator/web/spay/payment/data", */
        GET_COUNTRY :'https://pro.ip-api.com/json?key=RMV9nkZYs1rNPZQ',
        Neutrinoapi :'https://neutrinoapi.net/',
        NeutrinoKey: 'HBYAkwD7MvWPGvs9w4kle2BXrOnOwLBuhRGQ14NfJylEE5rF',
        API_LIST: {
            CHECK_TOKEN : 'sandbox/check-product',
            CHARGE_REQUEST:'sandbox/payment-request',
            QtyChange : 'https://api.foloosi.com/v1/customer/product/quantity-update'
        }
    }
}