import React, { Component } from 'react';
import { Countries } from './countries';
import { connect } from 'react-redux';
import { SELECT_COUNTRY,SHOW_COUNTRY } from '../constants/actionTypes';

class PhoneCode extends Component {    
    constructor (props) {
        super(props);
        this.state = {
          countryList : [],
          searchCountry:""
        }
        this.wrapperRef = React.createRef();
        this.getCountryDetails = this.getCountryDetails.bind(this);
        this.searchCountry = this.searchCountry.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }
    handleClickOutside(e) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
            this.props.show_country(e); 
        }
    }
    componentDidMount(){
        var _this = this;	
        if(_this.props.showFirst){
            const filterCountry = Countries.filter(prod => prod.name !== _this.props.showFirst.country_name)
            const filter_obj = Countries.filter(prod => prod.name == _this.props.showFirst.country_name)
            _this.setState({
                countryList: _this.state.countryList.concat(filter_obj,filterCountry)
            })
        }
        document.addEventListener("mousedown", this.handleClickOutside);
    }
    searchCountry(e){
        var self = this;
        self.setState({[e.target.name]: e.target.value}, () => {
            var search = self.state.searchCountry;
            if(search.length >= 1){
                var matchedElements = self.state.countryList.filter((item) =>
                    item.name.toLowerCase().indexOf(search.toLocaleLowerCase()) > -1 || item.code.toLowerCase().indexOf(search.toLocaleLowerCase()) > -1
                );
                if (matchedElements.length === 0) {
                    const filterCountry = Countries.filter(prod => prod.name !== self.props.showFirst.country_name)
                    const filter_obj = Countries.filter(prod => prod.name == self.props.showFirst.country_name)
                    self.setState({
                        countryList: self.state.countryList.concat(filter_obj,filterCountry)
                    })      
                } else{
                    self.setState({countryList:matchedElements});
                }
            }
            else{
                const filterCountry = Countries.filter(prod => prod.name !== self.props.showFirst.country_name)
                const filter_obj = Countries.filter(prod => prod.name == self.props.showFirst.country_name)
                self.setState({
                    countryList: self.state.countryList.concat(filter_obj,filterCountry)
                })
            }
        });
    }
    getCountryDetails(e){
        this.props.get_country(e);
        this.props.show_country(e);
    }
    render() {
        const countryList = this.state.countryList.map(function(country,i) {
			return(
				<li key={i} value={country.code} className="country preferred" data-dial-code={country.code} data-country-code={country.flag} onClick={(e) => this.getCountryDetails(country)}>
                    <div className="flag-box">
                        <div className={"iti-flag "+country.flag}></div>
                    </div>
                    <span className="country-name">{country.name}</span>
                    <span className="dial-code">{country.code}</span>
                </li>
			)
		},this);
        return (
            <ul ref={this.wrapperRef}
            className="country-list"                
            >
                <li className="search_input"><input placeholder="Search By Country Name or Code" type="text" name="searchCountry" value={this.state.searchCountry} onChange={e => this.searchCountry(e)}/></li>
                { countryList }
            </ul> 
        );
    }
}

const mapStateToProps = state => {
    return {
        selected_coutry : state.AppReducer.selected_country
    }
};
  
const mapDispatchToProps = dispatch => ({
    get_country : (country) => dispatch({ type: SELECT_COUNTRY, payload: country }),
    show_country : () => dispatch({ type: SHOW_COUNTRY }),
});

export default connect(mapStateToProps,mapDispatchToProps)(PhoneCode);