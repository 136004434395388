import React, { Component } from 'react';
import {isMobile, osName} from 'react-device-detect';

class MobileTopHeader extends Component {
    constructor (props) {
      super(props);
      this.state = {
        AppBar:true
      }
      this.renderContent = this.renderContent.bind(this);
      this.closeAppBar = this.closeAppBar.bind(this);
    }
    closeAppBar(event){
        this.setState({AppBar:false})
    }
    componentDidMount(){
        if (isMobile) {
            /*if(osName === 'iOS'){
                window.location.replace("FoloosiApp://?"+this.props.reference_id);
                setTimeout(function() {
                    if (!document.webkitHidden) {
                        window.location.replace(Path.USER_URL+Path.PAGE_NAME.Root+'/'+this.props.reference_id);
                    }
                }, 500);
            }*/
        }
    }
    renderContent(){
        if (isMobile) {
            if(osName === 'Android'){
                return( 
                    this.state.AppBar === true &&
                    <div className="mobileapp" data-os="android">
                        <div className="logo-img-app">
                            <span onClick={(event) => this.closeAppBar(event)} className="app_close">X</span>
                            <span className="logoinstall"></span>
                            <span className="app-cont">Android Customer App<p>Get your app</p></span>
                            <a target="_blank" className="app-get" href="https://play.google.com/store/apps/details?id=com.foloosi.customer&hl=en">GET</a>
                        </div>
                    </div>                    
                )
            }
            if(osName === 'iOS'){
                return(
                    this.state.AppBar === true &&
                    <div className="mobileapp" data-os="ios">
                        <div className="logo-img-app">
                            <span onClick={(event) => this.closeAppBar(event)} className="app_close">X</span>
                            <span className="logoinstall"></span>
                            <span className="app-cont">iOS Customer App<p>Get your app</p></span>
                            <a target="_blank" className="app-get" href="https://itunes.apple.com/us/app/foloosi/id1424241732?ls=1&mt=8">GET</a>
                        </div>
                    </div>
                    
                )
            }
        }
        return null
    }
    render() {
        return this.renderContent();
    }
}
          
export default MobileTopHeader;