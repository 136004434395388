import React, { Component } from 'react';
import {imagePath} from '../actions/ImagePath';
import ScrollToTop from "react-scroll-to-top";

class Footer extends Component {
    render() {
        return (
            <>
            <ScrollToTop smooth top="20"/>
            <div className="flp_mainBot">
                <div className="flp_powered">Powered by <img src={imagePath('./logo.png')} alt=""/></div>
            </div>
            </>
        );
    }
}
export default Footer;