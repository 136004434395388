import React, { Component } from "react";
import { connect } from "react-redux";
import dataservice from '../Services/DataService';
import GBLVAR from '../GlobalVariables/globalvaribles';
import { imagePath } from "../actions/ImagePath";
import Swipe from "react-easy-swipe";
const captionStyle = {
  fontSize: "2em",
  fontWeight: "bold",
};
const slideNumberStyle = {
  fontSize: "20px",
  fontWeight: "bold",
};
var readMore = false;
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      main_image:"",
      readMore:true,
      termall:true,
      imageId:"",
      time: 2000,
      width: "850px",
      height: "350px",
      captionStyle: captionStyle,
      radius: "10px",
      slideNumber: true,
      slideNumberStyle: slideNumberStyle,
      captionPosition: "bottom",
      automatic: false,
      dots: true,
      pauseIconColor: "white",
      pauseIconSize: "40px",
      slideBackgroundColor: "#fff",
      slideImageFit: "cover",
      thumbnails: true,
      thumbnailWidth: "100px",
      showNavBtn: true,
      slide: 0,
      isPaused: false,
      change: 0,
      dataList:[],
      productInfoDetails:{},
      showImage:false,
      
    };
  }
  componentDidMount() {
      this.getProductDetails();
    
  }
  getProductDetails = () => {
    dataservice.getValues(GBLVAR.API_PRODUCT,this.props.ref_id,{})
    .then((response) => {        
        if(response.code == '1001'){
          this.props.history.push(GBLVAR.PAGE_NAME.LinkPaid);
        } 
        else{
          this.setState({productInfoDetails:response.data}, () => {
            this.setState({dataList:this.state.productInfoDetails.product_images, showImage:true}, () => {
              if(this.state.dataList?.length > 1){
                this.autoSlideFn();
                this.slideFn();
              }
            })
            this.props.handler();
          });
        }      
    })
  }
  showMainImage = (id) => {
    var self = this;
    self.setState({main_image:id});
  }
  showMore = () => {
    this.setState({readMore:false});
  }; 
  showLess = () => {
    this.setState({readMore:true});
  }
  termMore = () => {
    this.setState({termall:false});
  }; 
  termLess = () => {
    this.setState({ termall: true });
  }
  slideFn = () => {
    var slides = document.getElementsByClassName("carousel-item");
    var dots = document.getElementsByClassName("dot");
    var slideIndex = this.state.slide;
    var i;
    for (i = 0; i < this.state.dataList?.length; i++) {
        slides[i].style.display = "none";
    }
    for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
    }
    if (this.state.thumbnails) {
        var thumbnailsArray = document.getElementsByClassName("thumbnail");
        for (i = 0; i < thumbnailsArray.length; i++) { 
            thumbnailsArray[i].className = thumbnailsArray[i].className.replace(
                " active-thumbnail",
                ""
            );
        }
        if (thumbnailsArray[slideIndex] !== undefined)
        thumbnailsArray[slideIndex].className += " active-thumbnail";
        this.scrollTo(document.getElementById(`thumbnail-${slideIndex}`));
    }
    if (slides[slideIndex] !== undefined)
    slides[slideIndex].style.display = "block";
    if (dots[slideIndex] !== undefined) dots[slideIndex].className += " active";
    this.setState({slide:this.state.slide, isPaused:this.state.isPaused})
  }
  autoSlideFn = () => {
      if (this.state.automatic) {
          var index = this.state.slide;
          const interval = setInterval(
              () => {
                  if (!this.state.isPaused) {
                      this.setState({ slide: index }, () => {
                          this.slideFn();
                      })
                      index++;
                      if (index >= this.state.dataList?.length) index = 0;
                      if (index < 0) index = this.state.dataList?.length - 1;
                  }
              },
              this.state.time ? this.state.time : 2000
          );
          return () => {
              clearInterval(interval);
          };
      }
  }
  scrollTo = (el) => {
      const elLeft = el.offsetLeft + el.offsetWidth;
      const elParentLeft = el.parentNode.offsetLeft + el.parentNode.offsetWidth;

      // check if element not in view
      if (elLeft >= elParentLeft + el.parentNode.scrollLeft) {
          el.parentNode.scroll({ left: elLeft - elParentLeft, behavior: "smooth" });
      } else if (elLeft <= el.parentNode.offsetLeft + el.parentNode.scrollLeft) {
          el.parentNode.scroll({
              left: el.offsetLeft - el.parentNode.offsetLeft,
              behavior: "smooth",
          });
      }
  }
  thumbnailView = (index) => {
      this.setState({ slide: index,change: !this.state.change }, () => {
          this.slideFn();
      })
  }
  setNavBtn = (id) => {
      if(id == "prev"){
          this.addSlide(-1);
      } else{
          this.addSlide(1);
      }
      this.setState({ change: !this.state.change })
  }
  addSlide = (n) => {
    if (this.state.slide + n >= this.state.dataList.length) {
        this.setState({ slide: 0 }, () => {
            
            this.slideFn();
        })
    }
    else if (this.state.slide + n < 0){
        this.setState({ slide: this.state.dataList.length - 1 }, () => {
         
            this.slideFn();
        })
    }            
    else {
        this.setState({ slide: this.state.slide + n }, () => {
           
            this.slideFn();
        })
    };
};
  
  render() {
    var self = this;
    var getdetails = self.state.productInfoDetails;
    let {dataList} = this.state;
    return (
      <div className="fpl_merchant_left">
        {getdetails && (
          <>
            <div className="fpl_merchant_leftTop">
             
            {getdetails?.user?.payment_screen_logo ===""?"" :
              getdetails?.user?.payment_screen_logo ?
              <div className="fpl_smalLogo_wrap">
                <img
                  src={ getdetails?.user?.payment_screen_logo}
                  className="fpl_smalLogo"
                />
              </div>
              : getdetails?.user?.profile_image &&
              <div className="fpl_smalLogo_wrap">
              <img
                src={ getdetails?.user?.profile_image}
                className="fpl_smalLogo"
              />
            </div>}
              {/* } */}
              <div
                className={
                  getdetails?.user?.payment_gateway === "ngenius"
                    ? "fpl_merchant_leftTop_right"
                    : "fpl_merchant_leftTop_right_full"
                }
              >
                <div className="fpl_merchant_name">
                  {getdetails?.user?.business_name}
                </div>
                {this.props.ord_id?<div className="fpl_payment_id">#{this.props.ord_id}</div>:""}
              </div>
            </div>
            {this.props.ord_id? <div className="fpl_merchant_leftMid">
                        <div className="fpl_merchant_midTitle">Total Payable Amount</div>
                        <div className="fpl_merchant_midAed">
                        {this.props.payable?<span className="aed_aligns">
          											AED
													</span>:""}{this.props.payable? this.props.payable.toFixed(2) : getdetails?.shipping_option==="Yes" ?
												getdetails.grand_total
											  : getdetails.grand_total}</div>
                    </div>:""}
            <div className="product_wrapper">
              <div className="productleft_wrapper">
                <div className="product_name"> {getdetails.product_name}</div>
                {dataList?.length <=1 ?
                <div className="product_image">
                  <img src={dataList[0]?.image_url}/>
                </div>
                :
                <div className="product_image">
                    {this.state.showImage &&
                    <>
                    <div className="responsive_view"
                        style={{
                            maxWidth: this.state.width ? this.state.width : "600px",
                            maxHeight: this.state.height ? this.state.height : "400px",
                        }}
                    >
                        <Swipe
                            onSwipeRight={() => {
                                this.addSlide(-1);
                                // setChange(!change);
                                this.setState({ change: !this.state.change })
                            }}
                            onSwipeLeft={() => {
                                this.addSlide(1);
                                // setChange(!change);
                                this.setState({ change: !this.state.change })
                            }}
                        >
                            <div
                                className="carousel-container"
                                style={{
                                    maxWidth: this.state.width ? this.state.width : "600px",
                                    height: this.state.height ? this.state.height : "400px",
                                    backgroundColor: this.state.slideBackgroundColor
                                        ? this.state.slideBackgroundColor
                                        : "darkgrey",
                                    borderRadius: this.state.radius,
                                }}
                            >
                                {dataList?.map((item, index) => {
                                    return (
                                        <div
                                            className="carousel-item fadeImage"
                                            style={{
                                                maxWidth: this.state.width ? this.state.width : "600px",
                                                maxHeight: this.state.height ? this.state.height : "400px"
                                            }}
                                            onMouseDown={(e) => {
                                                this.state.automatic && this.setState({ isPaused: true });
                                            }}
                                            onMouseUp={(e) => {
                                                this.state.automatic && this.setState({ isPaused: false });
                                            }}
                                            onMouseLeave={(e) => {
                                                this.state.automatic && this.setState({ isPaused: false });
                                            }}
                                            onTouchStart={(e) => {
                                                this.state.automatic && this.setState({ isPaused: true });
                                            }}
                                            onTouchEnd={(e) => {
                                                this.state.automatic && this.setState({ isPaused: false });
                                            }}
                                            key={index}
                                        >
                                            {this.state.slideNumber && (
                                                <div className="slide-number" style={this.state.slideNumberStyle}>
                                                    {index + 1} / {dataList.length}
                                                </div>
                                            )}
                                            <img
                                                src={item.image_url}
                                                //alt={item.caption}
                                                className="carousel-image"
                                                style={{
                                                    borderRadius: this.state.radius,
                                                    objectFit: this.state.slideImageFit ? this.state.slideImageFit : "cover",
                                                }}
                                            />
                                            {this.state.isPaused && (
                                                <div
                                                    className="pause-icon pause"
                                                    style={{
                                                        color: this.state.pauseIconColor ? this.state.pauseIconColor : "white",
                                                        fontSize: this.state.pauseIconSize ? this.state.pauseIconSize : "40px",
                                                    }}
                                                >
                                                    II
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                                {this.state.showNavBtn && (
                                    <a
                                        className="prev"
                                        onClick={() => this.setNavBtn('prev')}
                                    >
                                        &#10094;
                                    </a>
                                )}
                                {this.state.showNavBtn && (
                                    <a
                                        className="next"
                                        onClick={() => this.setNavBtn('next')}
                                    >
                                        &#10095;
                                    </a>
                                )}
                                {this.state.dots && (
                                    <div className="dots">
                                        {dataList?.map((item, index) => {
                                            return (
                                                <span
                                                    className="dot"
                                                    key={index}
                                                    onClick={() => this.thumbnailView(index)}
                                                ></span>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        </Swipe>
                    </div>
                    {this.state.thumbnails && (
                        <div
                            className="thumbnails"
                            id="thumbnail-div"
                            style={{ maxWidth: this.state.width }}
                        >
                            {dataList?.map((item, index) => {
                                return (
                                     <img
                                        width={this.state.thumbnailWidth ? this.state.thumbnailWidth : "100px"}
                                        src={item.image_url}
                                        //alt={item.caption}
                                        className="thumbnail"
                                        id={`thumbnail-${index}`}
                                        key={index}
                                        onClick={() => this.thumbnailView(index)}
                                    />
                                );
                            })}
                        </div>
                    )}
                    </>
                  }
                </div>
                }
               
                {getdetails.product_description?<div className="product_wrap">
                  {getdetails.language==="EN"?<div className="product_content">Product Description</div>
                  :<div className="product_content">وصف المنتج</div>}
                  <div className="product_content_text">
                    <p dangerouslySetInnerHTML={{__html:(this.state.readMore && getdetails.product_description.length > 150) ? getdetails.product_description.slice(0,150) : getdetails.product_description}}></p>
                    { this.state.readMore && getdetails.product_description.length > 150 ?
                    <a onClick={(e) => this.showMore(e)}>Read more</a>
                    :
                    getdetails.product_description.length < 150 ?"":
                    <a onClick={() => this.showLess()}>Read less</a>
                    }
                  </div>
                </div>:""}
                <div className="product_wrap">
                  {getdetails.language==="EN"?<div className="product_content"> Share this on</div>:
                  <div className="product_content"> شارك هذا على</div>}
                  <div className="share_images">
                    <a
                      target="_blank"
                      className="shareon_img"
                      href={`https://www.facebook.com/sharer/sharer.php?u=https://www.foloosi.com/product/preview/${getdetails.product_url}`}
                    >
                      <img
                        src="https://www.foloosi.com/images/fb-icon.png"
                        width="30"
                        height="30"
                      />
                    </a>
                    <a
                      target="_blank"
                      className=" shareon_img m-l-15"
                      href={`https://twitter.com/share?url=https://www.foloosi.com/product/preview/${getdetails.product_url}`}
                    >
                      <img
                        src="https://www.foloosi.com/images/twitter_icon.png"
                        width="30"
                        height="30"
                      />
                    </a>
                    <a
                      target="_blank"
                      className="shareon_img m-l-15"
                      href={`https://api.whatsapp.com/send?text=https://www.foloosi.com/product/preview/${getdetails.product_url}`}
                      data-action="share/whatsapp/share"
                    >
                      <img
                        src="https://www.foloosi.com/images/whatsapp_icon.png"
                        width="30"
                        height="30"
                      />
                    </a>
                  </div>
                </div>
                <div className="product_wrap">
                  {getdetails.language==="EN"?<div className="product_content">Contact Us</div>
                  :<div className="product_content"> اتصل بنا</div>}
                {getdetails?.user?.product_setting?.contact_email === " " ? " "
                :<div className="email_icon">
                    <a target="_blank" href={`mailto:${getdetails?.user?.product_setting?.contact_email}`}>
                      <img src="https://www.foloosi.com/images/email_icon.png" />
                      <span className="mail_content">{getdetails?.user?.product_setting?.contact_email}</span>
                    </a>
                  </div>}
                  {getdetails?.user?.product_setting?.contact_mobile === " " ? "" 
                  :  <div className="email_icon m-t-15">
                    <a target="_blank" href={`tel:${getdetails?.user?.product_setting?.contact_mobile}`}>
                      <img src="https://www.foloosi.com/images/call_icon.png" />
                      <span className="mail_content">{getdetails?.user?.product_setting?.contact_mobile}</span>
                    </a>
                  </div>}
                </div>
                {getdetails?.product_terms?.length > 0 && 
                <div className="product_wrap">
                {getdetails.language==="EN"?<div className="product_content">Terms &amp; Conditions</div>
                :<div className="product_content">البنود و الظروف</div>}
                  <div className="product_content_text">
                    <p>
                   
                      {getdetails?.product_terms?.map((prodterm)=>(
                        <>
                        <span dangerouslySetInnerHTML={{__html:(this.state.termall && prodterm.term.length > 150)?prodterm.term.slice(0,150):prodterm.term}}></span>
                        <span>{this.state.termall && prodterm.term.length > 150 ? 
                        <a onClick={() => this.termMore()}>View Term</a>
                       
                      : 
                      prodterm.term.length < 150 ?"":
                       <a onClick={() => this.termLess()}>Hide Term</a>}</span>
                        
                      </>
                      ))}

                      
                    </p>
                  </div>
                </div>}
              </div>
            </div>
          
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    state: state.AppReducer,
  };
};

export default connect(mapStateToProps)(Header);
