import React, { Component } from 'react';
import { connect } from 'react-redux';
import {imagePath} from '../actions/ImagePath';
import dataservice from '../Services/DataService';
import GBLVAR from '../GlobalVariables/globalvaribles';
import axios from 'axios';

class Success extends Component {
    constructor (props) {
        super(props);
        var app_details = window.parent.app_details;
        this.state = {
            app_details:app_details,
            transaction_no:"",
            orderTotal:window.parent.orderTotal,
            amount:window.parent.amount
        }
    }
    componentDidMount() {
        var _this=this;
        var getUrlParams = new URLSearchParams(window.location.search);
        //var urlParams = JSON.parse(getUrlParams.get('q'));
        _this.setState({transaction_no:getUrlParams.get('transaction_no')}, () => {        
            setTimeout(() => {
                var dataSuccess = {"status":"success","data": {"payment_status": "Transaction Success"}}
                window.top.parent.postMessage(dataSuccess, "*")
            }, 2000);
        }); 
    }
    render() {
        return (
            <div className="fpl_merchant_rightMid text-center bg_remove">
                <div className="status_heading">Payment Completed</div>
                <p className="status_trans_id">Payment ID: #{this.state.transaction_no}</p>
                <div className="status_trans_icon">
                <img className="successPayimg" src={imagePath('./success.svg')} />
                </div>
                <div className="status_trans_message">You have successfully paid AED {this.state.amount?this.state.amount:this.state.orderTotal}</div>
                <div className="fpl_mob_wrap"><a className="status_done_btn">Done</a></div>
            </div>
        );
    }
}
          
const mapStateToProps = state => {
	return {
		state : ""
	}
};
          
export default connect(mapStateToProps)(Success);