import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from './Header';
import Footer from './Footer';
import {imagePath} from '../actions/ImagePath';
import GBLVAR from '../GlobalVariables/globalvaribles';

class SamsungCancel extends Component {
    constructor (props) {
      super(props);
      this.state = {
        successdata:{},
        cancelMessage:"",
        paydetails:{},
        App_Details_enable:false,
         headerState:true,
        product_id:""
      }
      this.goBack = this.goBack.bind(this);
      this.handler = this.handler.bind(this);
    }
    goBack(){
        this.props.history.push(GBLVAR.PAGE_NAME.Home+'/'+this.state.App_Details.product_id);
    }
    handler() {
		this.setState({
		  headerState: false
		})
    }
    componentDidMount() {
        var _this = this;
        var getUrlParams = new URLSearchParams(window.location.search);
        var urlParams = getUrlParams.get('ref_id');
        var orderdata = getUrlParams.get('orderdata');
        var transaction_no = getUrlParams.get('transaction_no');
        var getOrderData = JSON.parse(decodeURI(orderdata));
        _this.setState({product_id:getUrlParams.get('product_id'),itemId:getOrderData.transaction.product_id}, ()=>{
            window.app_details = getOrderData;
            console.log("sdfsd",getOrderData)
        document.documentElement.style.setProperty('--main-theme-color','#'+getOrderData?.merchant?.theme_color);
        document.documentElement.style.setProperty('--main-primary-color','#'+getOrderData?.merchant?.primary_color);
        _this.setState({ref_id:urlParams,orderdata:orderdata,transaction_no:transaction_no,App_Details_enable:true}, () => {
            _this.setState({
                App_Details: {
                    "payment_gateway": getOrderData.merchant.payment_gateway,
                    "logo" : getOrderData.merchant.payment_screen_logo,
                    "business_name": getOrderData.merchant.business_name,
                    "transaction_no" : getOrderData.transaction_no,
                    "currency": getOrderData.currency,
                    "transaction_amount" : getOrderData.amount,
                    "description": getOrderData.description,
                    "receipt_no": getOrderData.receipt_no,
                    "product_no": getOrderData.transaction.reference_id,
                    "product_id": getOrderData.transaction.product_id,
                    "created" : getOrderData.created
                },
                orderData: {
                    "customer" : getOrderData.customer,
                    "transaction" : {
                        "reference_id": getOrderData.transaction.reference_id,
                        "success_url" : GBLVAR.USER_URL + GBLVAR.PAGE_NAME.Success + '?refid=' +  _this.state.transaction_no,
				        "cancel_url": GBLVAR.USER_URL + GBLVAR.PAGE_NAME.Cancel + '?refid=' + _this.state.transaction_no,
                    }
                },
                transaction_no: getUrlParams.get('transaction_no')
            });
        })
    })
    }
    render() {
        return (
            <>
            <div className="fpl_body">
				<div className="fpl_body_center">
                    <div className="col-xs-12 col-sm-7 col-md-7">
                {this.state.product_id !== '' &&
                   <Header handler={this.handler} ref_id={this.state.product_id} ord_id={this.props.product_id} payable={this.state.amount} />   		
                    }
                    </div>
                    <div className="col-xs-12 col-sm-5 col-md-5">
                <div className="fpl_merchant_right">
                        <div className="fpl_merchant_rightTop">
							<div className="fpl_merch_detail">Payment Details</div>
						</div>
                        <div className="fpl_merchant_rightMid">
                    <div className="fl_pay_section text-center bg_remove">
                        <div className="status_heading"> Payment Cancelled</div>
                        <div className="status_trans_icon">   
                            <img src={imagePath('./cancel.svg')} />
                        </div>
                        <div className="status_trans_message">Note : Samsung Pay not supported</div>
                        <div className="fpl_mob_wrap"><a onClick={e => this.goBack()} className="status_error_btn">Retry Payment</a></div>
                    </div>
                    </div>
                    <div className="fpl_merchant_rightBot">
							<img className="pci_icon" src={imagePath('./pci.png')} alt=""/>
							<img src={imagePath('./visa.png')} alt=""/>
							<img src={imagePath('./master.png')} alt=""/>
							<img src={imagePath('./amex.png')} alt=""/>
						</div>
                        </div>
                        </div>
                </div>
            </div>
            </>
        );
    }
}
          
const mapStateToProps = state => {
	return {
		App_Details : state.AppReducer.App_Details
	}
};
          
export default connect(mapStateToProps)(SamsungCancel);





